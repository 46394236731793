import { Center, Flex, Image, useColorModeValue } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import Lightlogo from "../../../assets/breign-logo-light.png";
import Darklogo from "../../../assets/breign-logo-dark.png";

export default function NoAuthLayout() {
  const logo = useColorModeValue(Lightlogo, Darklogo);
  const navigate = useNavigate();
  return (
    <Flex flexDirection={"column"} h={"100svh"} flex={1}>
      <Center h={"200px"} >
        <Image
          w={"200px"}
          src={logo}
          alt="Breign Logo"
          onClick={() => navigate("/")}
        />
      </Center>
      <Center  flex={1}>
        <Outlet />
      </Center>
      <Center h={"40px"} fontSize={"xs"} bg={"tomato"}>
        Made with ❤️ by Victor Madu
      </Center>
    </Flex>
  );
}
