import { Box, Flex, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom";
import NavMenu from "../ui/NavMenu";
import TopBar from "../ui/TopBar";
import { useState } from "react";
import { mobileQuery } from "../../services/constants";

function Layout() {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const getPageName = (pathname: string): string => {
    switch (pathname) {
      case "/":
        return "Dashboard";
      case "/students":
        return "Students";
      case "/results":
        return "Results";
      case "/settings":
        return "Settings";
      case "/settings/school":
        return "School Settings";
      case "/settings/session":
        return "Session Settings";
      case "/settings/term":
        return "Term Settings";
      case "/settings/class":
        return "Class Settings";
      case "/settings/grade":
        return "Grade Criteria Settings";
      case "/settings/level":
        return "Level Settings";
      case "/settings/subject":
        return "Subject Settings";
      case "/settings/user":
        return "User";
      default:
        return "";
    }
  };

  const pageName = getPageName(location.pathname);

  const mainAreaColor = useColorModeValue("gray.50", "gray.900");

  const [isMobile] = useMediaQuery(mobileQuery);

  return (
    <Flex direction="column" h={"100svh"} overflow="hidden">
      <TopBar pageName={pageName} isCollapsed={isCollapsed} />

      <Flex flex={1} overflow="hidden">
        {!isMobile && (
          <NavMenu isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        )}

        <Box as="main" flex="1" overflow="hidden" bg={mainAreaColor}>
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
}

export default Layout;
